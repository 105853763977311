
// Fonts & Icons
@import 'partials/fonts';

// Base Styling
@import 'base';

// UI Elements
@import 'partials/elements';
@import 'partials/navbar';
@import 'partials/livechat';

// Pages
@import 'pages/home';
@import 'pages/contact';
@import 'pages/notice';
@import 'pages/services';
@import 'pages/testimonials';
@import 'pages/privacy-policy';
@import 'pages/phone';