.noticeContent {
    position: absolute;
    right:50%;
    bottom:50%;
    transform:translate(50%, 50%);


    width:600px;
    background:rgba(20, 20, 20, .25);
    color:whiteSmoke;
    padding:25px;
    border-radius:3px;
    .pageHeader {
        padding-bottom:5px;
        margin-bottom:5px;
        border-bottom:solid 1px rgb(207, 207, 207);
    }
}