#home, #phone {
    background:#111;
    .pageHeader {
        margin-top:30px;
        margin-bottom:15px;
    }

    .banner {
        height:100vh;
        overflow:hidden;
        position: relative;
        border-radius:3px;
        background-size: cover;
        background-position: center;
        transition:background-image linear .2s;

        &.background_1 {
            background-image:url(/static/img/home/laptop_notepad.webp);
            transition:background-image linear .2s;
        }
        &.background_2 {
            background-image:url(/static/img/home/business_laptop.webp);
            transition:background-image linear .2s;
        }
        &.background_3 {
            background-image:url(/static/img/home/wordpress_laptop.webp);
            transition:background-image linear .2s;
        }
        &.background_4 {
            background-image:url(/static/img/home/pingpong_laptop.webp);
            transition:background-image linear .2s;
        }

        .homeContent {
            position: absolute;
            bottom:50%;
            transform:translateY(50%);
            text-align: center;
            width:100%;
            padding:30px;
            background:rgba(0, 0, 0, .5);
            .header {
                font-size:20pt;
            }

            animation-delay: 1s;
            animation: linear homeContentFadeIn .5s forwards;
            opacity:0;
            @keyframes homeContentFadeIn {
                0% { opacity:0; }
                100% { opacity:1 }
            }
        }
        .phoneCallout {
            position: absolute;
            left:50px;
            bottom:50px;
            
            border:solid 1px red;
            box-shadow:0px 0px 5px rgba(255, 0, 0, .5);
            border-radius:5px;
            overflow:hidden;

            @media only screen and (max-width: 600px) {
                right:50px;
            }
            @media only screen and (max-height: 400px) {
                left:10px;
                bottom:10px;
            }

            a {
                display:flex;
            }
            .image {
                overflow:hidden;
                width:80px;
                height:80px;
                background-color:rgba(0, 0, 0, .15);
                background-image:url(/static/img/home/phone_repair_callout.webp);
                background-size:cover;
                background-position: center;
            }
            .content {
                padding:15px;
                background-color:rgba(0, 0, 0, .5);
                font-size:10pt;
                color:white !important;
                .title {
                    font-size:15pt;
                }
                @media only screen and (max-width: 600px) {
                    flex:1;
                }
            }
        }
        img {
            position: absolute;
            bottom:50%;
            transform: translateY(50%);
        }
    }

    .contactDetails {
            
        padding:10px;

        div {
            flex:1;
            font-weight: 200;
            font-size:13pt;
            margin:10px;
            @media only screen and (max-width: 600px) {
                padding-top:7px;
                padding-bottom:7px;
            }
            .icon {
                background:#C82626;
                padding:5px;
                border-radius:3px;
            }
        }
    }

    .section {
        padding-bottom:50px;
        padding-top:50px;

        background-color:#1E1E1E;

        @media only screen and (max-width: 600px) {
            padding:30px;
        }
        
        @media only screen and (max-height: 400px) {
            padding:30px;
        }

        &.repair {
            background-image:url(/static/img/home/section_repair.png);
            z-index: 10;
            position: relative;

            animation:ease-in-out 2s popup;

            @media only screen and (max-width: 600px) {
                animation:none;
            }

            @keyframes popup {
                0% { margin-top: 0px; }
                50% { margin-top: -100px; }
                100% { margin-top: 0px; }
            }
        }

        &.full-view {
            min-height:100vh;
            padding-top:70px;
            @media only screen and (max-width: 600px) {
                padding:10px;
            }
        }

        .sectionTop {
            text-align:center;
            margin-bottom:10px;
            @media only screen and (max-width: 600px) {
                padding:30px;
            }
        }
        .sectionHeader {
            font-size:20pt;
            margin-bottom:5px;
            font-weight:300;
            @media only screen and (max-width: 600px) {
                text-align: center;
                margin-bottom:15px;
            }
        }
    }

    .section.services {
        background-image: url(/static/img/global/104a_dark.webp);
        background-position:50% 50%;
        background-size:cover;

        display: flex;
        align-items: center;

        .service {
            margin:10px;
            background:rgba(17, 17, 17, 0.7);
            border-radius:3px;
            padding:10px;
            display:inline-block;
            width:31%;

            @media only screen and (max-width: 600px) {
                width:auto;
                display:block;
            }
            @media only screen and (max-height: 400px) {
                width:45%;
            }

            .serviceIcon {
                display: inline-block;

                color:white;
                width:64px;
                height:64px;
                background: linear-gradient(#d6201d, #840503);
                border:solid 2px #a8110e;
                
                text-align: center;
                line-height:64px;
                border-radius: 100%;

                font-size:23pt;

                transition:border-color ease-in .1s;

            }
            .details {
                display:inline-block;
                margin-left:10px;

                .serviceName, .serviceInfo {
                    color:white;
                }
                .serviceInfo {
                    font-size:9pt;
                }
            }
        }
        .service:hover {
            background:rgba(27, 27, 27, 0.7);
            .serviceIcon {
                cursor: pointer;
                border-color:#E31D1A;
                transition:border-color linear .1s;
            }
        }
    }

    .section.testimonials {
        background-image:url(/static/img/global/section_feedback.png);
        .feedback {
            padding:15px;
            background:rgba(17, 17, 17, 0.7);
            border-radius:3px;
            display:flex;
            color:white;

            .avatar {
                padding-right: 5px;
                img {
                    width:64px;
                    height:64px;
                    border-radius:100%;
                    //border:solid 2px #C82626;
                }
            }
            .content {
                flex:1;
                padding:5px;
                .name {
                    font-size:11pt;
                    color:rgb(231, 75, 75);
                }
                .message {
                    font-size:10pt;
                }
            }
        }
    }

    .section.contact {
        background:url(/static/img/global/map.webp);
        position:relative;

        .contactContent {

            position: absolute;
            right:50%;
            bottom:50%;
            transform: translate(50%, 50%);
    

            color:white;
            background:rgba(20, 20, 20, .75);
    
    
            //box-shadow:2px 2px 10px rgba(0,0,0,.25);
            padding:25px;
            border-radius:3px;
            .pageHeader {
                padding-bottom:5px;
                margin-bottom:5px;
                border-bottom:solid 1px silver;
            }
    
            display:flex;
            flex-direction: row;
    
            @media only screen and (max-width: 600px) {
                width:initial;
                position: relative;
                right:auto;
                bottom:auto;
                transform:none;
                flex-direction: column;
    
                min-width:initial;
                max-width:initial;
    
                margin:20px;
            }

            @media only screen and (max-height: 400px) {
                width:initial;
                position: relative;
                right:auto;
                bottom:auto;
                transform:none;
                flex-direction: column;
    
                min-width:initial;
                max-width:initial;
    
                margin:20px;
            }
    
            .leftPane, .rightPane {
                
    
                .contactHeader {
                    font-size:18pt;
                    font-weight:200;
                    margin-bottom:5px;
                    @media only screen and (max-width: 600px) {
                        text-align: center;
                    }
                }
            }
            .leftPane {
                flex:1;
                padding-right:25px;
                border-right:solid 1px rgb(201, 201, 201);
    
                @media only screen and (max-width: 600px) {
                    border-right:none;
                    border-bottom:solid 1px rgb(201, 201, 201);
                    padding-right:0;
                    padding-bottom:25px;
                }
                @media only screen and (max-height: 400px) {
                    border-right:none;
                    border-bottom:solid 1px rgb(201, 201, 201);
                    padding-right:0;
                    padding-bottom:25px;
                }
            }
            .rightPane {
                width:280px;
                padding-left:25px;
    
                @media only screen and (max-width: 600px) {
                    width:auto;
                    padding-top:25px;
                    padding-left:0;
                }
            }
        }
    }
}
body.homepage {
    .bottom_footer {
        position: relative;
    }
}