#livechat {
    display:block;

    position: fixed;
    right:60px;
    bottom:55px;
    
    width:80px;
    height:80px;

    .chatBubble {
        position: absolute;
        left:-130px;
        top:25px;
        background:rgba(0,0,0,.5);
        width:110px;
        padding:5px;
        color:white;
        text-align: center;
        border-radius:5px;

        animation: float ease-in-out 2s infinite;

        @keyframes float {
            0% { left: -135px; }
            50% { left: -130px; }
            100% { left: -135px; }
        }
    }
    .button {
        box-shadow: 0 0 10px rgba(255,0,0,.5);
        border-radius:100%;
        overflow:hidden;
        height:80px;
        img {
            height:100%;
        }
        &:hover {
            cursor:pointer;
        }
    }
    
}