#masthead.testimonials {
    background-image: url(/static/img/testimonials/store.webp);
    text-align:center;
    color:white;
    .pageHeader {
        margin-bottom:5px;
        font-size:20pt;
    }
    .pageSubHeader {
        margin-bottom:20px;
        color:whiteSmoke;
    }
    .columns {
        display:flex;
        overflow-y: auto;
        max-height:80vh;

        @media only screen and (max-width: 600px) {
            flex-direction: column;
            overflow: initial;
            max-height: none;
        }

        .column {
            flex:1;
            margin-bottom:20px;
            display:flex;
            flex-direction:column;

            @media only screen and (max-width: 600px) {
                display: block;
                padding:5px;
            }

            &:first-child {
                margin-right:20px;

                @media only screen and (max-width: 600px) {
                    margin-right:0;
                }
            }
            &:last-child {
                margin-left:20px;

                @media only screen and (max-width: 600px) {
                    margin-left:0;
                    margin-bottom: 0;
                }
            }
        }
        // Testimonuals have moved into the main elements file
    }
}