.bodyContent {  
    padding:20px;
    padding-top:70px;
    @media only screen and (max-width: 600px) {
        padding-top:20px;
        margin-top: 50px;
    }
}
.footer {
    margin:10px;
    .container {
        border-top:solid 1px silver;
        padding:15px;
        padding-left:20px;
        padding-right:20px;
        font-size:11pt;
        color:whiteSmoke;
    }
}
.bottom_footer {
    position: absolute;
    bottom:0;
    width:100%;
    background:rgba(11,11,11,.5);
    padding: 8px;
    font-size: 10pt;
    color:white;

    @media only screen and (max-width: 600px) {
        text-align:center;
        font-size:8pt;
        position:fixed;
        .pull-right {
            float:initial;
            margin-top:5px;
        }
    }
}

.container {
    max-width:1200px;
    margin:0 auto;
}
.pageHeader {
    font-size:20pt;
}
.pageSubHeader {
    font-size:12pt;
    color:rgb(156, 156, 156);
    font-style:italic;
}
.cookieBubble {
    bottom: 44px;
}
#masthead {
    width:100vw;
    height:100vh;
    display:block;
    background:#111;
    
    background-size:cover;
    background-image: url(/static/img/global/hands_on_dark.webp);

    overflow:hidden;

    @media only screen and (max-width: 600px) {
        padding-top:128px;
        height:auto;
        min-height:100vh;
    }
    
    padding-bottom:50px;
    overflow-y:auto;

}

.testimonial {
    display:flex;
    margin-bottom:10px;
    flex-direction: column;

    opacity:0;
    @keyframes show {
        0% { opacity:0; }
        100% { opacity: 1; }
    }
    animation: show .2s linear 1 forwards;

    .avatar {
        width:auto;
        word-wrap: break-word;
        text-align: left;
        img {
            display:none;
            width:64px;
            height:64px;
            border-radius:100%;
        }
        .name {
            padding:5px;
            background:rgba(0,0,0,.5);
            padding-left:10px;
            padding-right:10px;
            display:inline-block;
            margin-left:10px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            font-size: 8pt;
        }
    }
    .content {
        background-color:rgba(255,255,255,.8);
        color:#111;
        padding:10px;
        border-radius:3px;
        text-align:left;


        .subject {
            margin-bottom:5px;
            font-size:12pt;

            display:none; // We don't need to see it for now

            &:empty, &:blank {
                display:none;
            }
        }
        .message {
            font-size:10pt;
            white-space: pre-line;
        }
    }
}

label, .label {
    font-weight:200;
    font-size:13pt;
    display:inline-block;
    padding-bottom:5px;
}

.pull-right {
    float:right;
}

* {
    box-sizing:border-box;
}
nav {
    display:inline;
}
a {
    color:rgb(255, 67, 67);
    text-decoration: none;
}
input, textarea {
    padding:5px;
    border-radius:3px;
    font-family: inherit;
    border:solid 1px silver;
    width:100%;
    outline:none;
    &:active, &:focus {
        border:solid 1px rgb(200, 38, 38);
    }
}
button, .btn {
    color:black;
    font-family: inherit;
    padding:5px;
    border-radius:2px;
    padding-left:10px;
    padding-right:10px;
    border:none;
    background:white;
    border:solid 1px transparent;
    transition:border linear .2s;
    display:inline-block;
    &:hover {
        border-color:grey;
        transition:border linear .2s;
        cursor: pointer;
    }

    &.btn-red {
        color:white;
        background:rgb(200, 38, 38);
        &:hover {
            border-color:rgb(143, 22, 22);
        }
    }

    &.btn-green {
        color:white;
        background:#5AC041;
        &:hover {
            border-color:rgb(69, 148, 50);
        }
    }
}

::-webkit-scrollbar {
    width: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(255,255,255,.3);
    transition:background-color .2s linear;

    @media only screen and (max-width: 600px) {
        background-color: rgba(255,255,255,.5);
    }
    &:hover {
        background-color:rgba(255,255,255,.7);
        transition:background-color .2s linear;
    }
}
::-webkit-scrollbar-thumb:window-inactive {
    background: silver; 
}