// USE HOME.SCSS INSTEAD
#phone {
    .banner {
        &.background_1 {
            background-image:url(/static/img/fixmyphone/background_1.webp);
            transition:background-image linear .2s;
        }
        &.background_2 {
            background-image:url(/static/img/fixmyphone/background_2.webp);
            transition:background-image linear .2s;
        }
        &.background_3 {
            background-image:url(/static/img/fixmyphone/background_3.webp);
            transition:background-image linear .2s;
        }
        &.background_4 {
            background-image:url(/static/img/fixmyphone/background_1.webp);
            transition:background-image linear .2s;
        }
    }
    
    .section.services {
        background-image: url(/static/img/fixmyphone/background_3_dark.webp);
    }
}