#masthead.contact {
    background-image: url(/static/img/global/map.webp);
    
    .contactContent {

        position: absolute;
        // Swapping stuff for the sake of IE/Edge bug
        right:50%;
        bottom:50%;
        transform:translate(50%, 50%);

        color:white;
        background:rgba(20, 20, 20, .75);

        max-width:1200px;
        min-width: 840px;

        //box-shadow:2px 2px 10px rgba(0,0,0,.25);
        padding:25px;
        border-radius:3px;
        .pageHeader {
            padding-bottom:5px;
            margin-bottom:5px;
            border-bottom:solid 1px silver;
        }

        display:flex;
        flex-direction: row;

        @media only screen and (max-width: 600px) {
            width:initial;
            position: relative;
            right:auto;
            bottom:auto;
            transform:none;
            flex-direction: column;

            min-width:initial;
            max-width:initial;

            margin:20px;
        }
        @media only screen and (max-height: 400px) {
            width:initial;
            position: relative;
            right:auto;
            bottom:auto;
            transform:none;
            flex-direction: column;

            min-width:initial;
            max-width:initial;

            margin:20px;
        }

        .leftPane, .rightPane {
            

            .contactHeader {
                font-size:18pt;
                font-weight:200;
                margin-bottom:5px;
            }
        }
        .leftPane {
            flex:1;
            padding-right:25px;
            border-right:solid 1px rgb(201, 201, 201);

            @media only screen and (max-width: 600px) {
                border-right:none;
                border-bottom:solid 1px rgb(201, 201, 201);
                padding-right:0;
                padding-bottom:25px;
            }
        }
        .rightPane {
            width:280px;
            padding-left:25px;

            @media only screen and (max-width: 600px) {
                width:auto;
                padding-top:25px;
                padding-left:0;
            }
        }
    }
}