body {
    margin:0;
    padding:0;
    background-color:#111;
    color:whitesmoke;
    background-size:cover;
    background-attachment: fixed;
    font-family: 'Open Sans', sans-serif;
    font-display: auto;
}
@media only screen and (max-width: 600px) {
    .container {
        width:100%;
        margin:initial;
    }
    .navbar {
        background:rgba(0,0,0,.5) !important;
    }
}
.error {
    color:white;
    background:rgb(231,76,60);
    padding:5px;
    border-radius:3px;
}