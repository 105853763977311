.navbar {
    position:fixed;
    left:0;
    top:0;
    width:100vw;
    background-color:rgba(0, 0, 0, .5);
    background:linear-gradient( rgba(0,0,0,.65), transparent);
    color:white;
    padding:15px;
    box-sizing:border-box;
    z-index:20;

    &.solid {
        background:rgba(0,0,0,.6);
    }

    .logo {
        // :D
        width:150px;
        height:35px;
        display:inline-block;
        img {
            height:100%;
        }
        
        @media only screen and (max-width: 600px) {
            margin-bottom:15px;
            width:100%;
            text-align:center;
        }
    }
    
    .menu {
        display:block;
        float:right;
        color:white;
        margin:0;
        font-size:10pt;
        list-style: none;
        padding:0;

        @media only screen and (max-width: 600px) {
            width:100%;
            text-align: center;
        }

        li {
            display:inline-block;

        }
        a.item {
            display: inline-block;
            color:inherit;
            padding:10px;
            padding-left:15px;
            padding-right:15px;

            border-bottom:solid 2px transparent;

            &:hover {
                transition:border .2s linear;

                border-bottom-color: white;
            }
        }
    }
}