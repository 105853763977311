#masthead.services {
    
    background-image: url(/static/img/global/104a_dark.webp);
    text-align: center;
    color:white;
    font-weight:200;

    .pageHeader {
        margin-bottom:30px;
        flex: 100%;
        @media only screen and (max-width: 600px) {
            font-size: 15pt;
        }
    }

    .container {
        display: flex;
        justify-content: center;
        align-content: center;
        max-width: 1000px;
        flex-wrap: wrap;
    }

    .qualityGuaranteed {
        padding:10px;
        width:100%;
        background:rgba(0,0,0,.5);
        font-weight:500;
        color:white;
        border-radius:3px;

        @media only screen and (max-width: 600px) {
            background:none;
        }
    }

    .service {
        padding:10px;
        background-color:#C82626;
        background:linear-gradient(rgba(255, 84, 84, 0.75),rgba(200, 38, 38, 0.75));
        border-radius:5px;
        display:inline-block;
        margin:10px;
        width:180px;
        height:180px;
        color:white;
        overflow:hidden;
        cursor:pointer;
        background-repeat: no-repeat;
        background-size:cover;

        border:solid 2px transparent;
        transition:border linear .75s;

        transform: scale(0,0);
        animation: appear .1s 1;
        animation-fill-mode: forwards;

        @media only screen and (max-width: 600px) {
            width:100px;
            height:100px;
            margin:0;
            padding:5px;
        }

        @keyframes appear {
            0% { transform:scale(0,0); }
            100% { transform:scale(1,1); }
        }
        &:hover {
            border-color:white;
            transition:border linear .2s;
        }

        .serviceIcon {
            font-size: 70px;
            align-content:center;
            text-align:center;
            flex:1;
            padding-top:5px;
            @media only screen and (max-width: 600px) {
                font-size:32px;
            }
        }
        .serviceName {
            flex:1;
            text-align:center;
            padding-top:10px;
            font-size:12pt;
            @media only screen and (max-width: 600px) { 
                font-size:9pt;
                padding-top:5px;
            }
        }
        .serviceInfo {
            flex:1;
            text-align:center;
            padding:5px;
            font-weight:200;
            font-size:8pt;
            @media only screen and (max-width: 600px) {
                display: none;
            }
        }
    }
}
#masthead.serviceInfo {
    .serviceContent {
        position: absolute;
        right:50%;
        bottom:50%;

        transform: translate(50%, 50%);

        display:flex;

        min-width:900px;

        flex-direction: column;

        @media only screen and (max-width: 600px) {
            position: relative;
            right:initial;
            bottom: initial;
            transform: none;
            min-width:200px;
        }

        .row {

            &.contentRow {
                display:flex;
                margin-top:10px;
                margin-bottom:10px;

                @media only screen and (max-width: 600px) {
                    flex-direction:column;
                }
            }

            .panelHeader {
                font-size:22pt;
            }
            .panelSubHeader {
                font-size:12pt;
                font-weight:200;
                @media only screen and (max-width: 600px) {
                    font-size:11pt;
                }
            }

            .leftPanel {
                width:260px;

                @media only screen and (max-width: 600px) {
                    width:auto;
                }
                .serviceImage {
                    width:250px;
                    height:250px; 
                    overflow: hidden;
                    
                    border-radius:3px;
                    background:rgba(0,0,0,.5);
                    
                    img {
                        height:100%;
                        position: relative;
                        left:50%;
                        transform: translateX(-50%);
                    }

                    @media only screen and (max-width: 600px) {
                        width:auto;
                        margin-bottom:10px;
                    }
                }
            }
            .rightPanel {
                display:flex;
                flex-direction: column;
                flex:1;
                .panelContent {
                    padding:10px;
                    background:rgba(20, 20, 20, .4);
                    flex:1;
                    border-radius:3px;

                    font-size:11pt;
                    font-weight:200;
                    height:250px;

                    a {
                        display: inline-block;
                    }

                    @media only screen and (max-width: 600px) {
                        height:auto;
                    }
                }
            }
            .btn {
                margin-left:10px;
            }
        }
    }
}